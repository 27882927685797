

import { Vue, Component, Prop } from 'vue-property-decorator'
import { Supplier } from '@/types/supplier.d'
import { CooperateStatusName } from '@/utils/enum'

@Component
export default class SupplierInfo extends Vue {
  @Prop() readonly id!: string;

  supplierId = ''
  detail: Supplier = {
    supplierName: '',
    supplierType: '',
    operationScope: '',
    supplierDesc: '',
    cooperateStatus: '',
    province: '',
    city: '',
    area: '',
    address: '',
    url: '',
    contactList: []
  }

  cooperateStatusName = CooperateStatusName

  created () {
    console.log('当前id:::::::', this.$route.params.id)
    this.supplierId = this.$route.params.id
    this.loadData()
  }

  loadData () {
    this.$axios.get<Supplier>(this.$apis.supplier.getSupplier, {
      supplierId: this.supplierId
    }).then(res => {
      res.address = res.province + res.city + res.area + res.address
      if (!res.address) {
        res.address = ''
      }
      this.detail = res
    })
  }
}
